import React from "react"
import { graphql } from "gatsby"
import BokaFormular from "../components/boka-formular"
import Kontakt from "../components/kontakt"
import Layout from "../components/layout"
import Container from "../components/container"

export default function KontaktPage({ data }) {
  const { title, content } = data.wpPage
  return (
    <Layout>
      <div className="bg-primary/30 min-h-screen">
        <div className="flex flex-col py-4 md:py-8  max-w-4xl mx-auto px-4 md:px-8">
          <div className="space-y-4">
            <h1 className="page-header">{title}</h1>
            <div
              dangerouslySetInnerHTML={{ __html: content }}
              className="wp-content"
            />
            <BokaFormular />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    wpPage(databaseId: { eq: 138 }) {
      content
      title
    }
  }
`
