import React from "react"

export default function Container({ children }) {
  return (
    <section>
      <div className=" flex flex-col py-4 md:py-8 container mx-auto px-4 md:px-8">
        {children}
      </div>
    </section>
  )
}
