import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default function Kontakt({}) {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 17 }) {
        informationStartsida {
          kontakt
        }
      }
    }
  `)

  const kontakt = data.wpPage.informationStartsida.kontakt
  return (
    <div className="space-y-2">
      <div
        dangerouslySetInnerHTML={{ __html: kontakt }}
        className="wp-content space-y-4"
      />
    </div>
  )
}
