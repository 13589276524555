import React from "react"

export default function BokaFormular() {
  return (
    <div className="py-4 md:py-8">
      <form
        action="https://getform.io/f/0ad870d2-5aa1-49df-9408-eaf9c11b6667"
        method="POST"
        className="form space-y-8"
      >
        <div className="grid md:grid-cols-2 gap-4">
          <div className="form-group ">
            <label htmlFor="Förnamn">
              Förnamn*
              <input
                type="text"
                name="Förnamn"
                required
                className="bg-white block mt-2 w-full p-4 text-black rounded-sm"
              />
            </label>
          </div>
          <div className="form-group ">
            <label htmlFor="Efternamn">
              Efternamn*
              <input
                type="text"
                name="Efternamn"
                required
                className="bg-white block mt-2 w-full p-4 text-black rounded-sm"
              />
            </label>
          </div>
          <div className="form-group ">
            <label htmlFor="Epost">
              Epost*
              <input
                type="email"
                name="Epost"
                required
                className="bg-white block mt-2 w-full p-4 text-black rounded-sm"
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="Telefon">
              Telefon
              <input
                type="text"
                name="Telefon"
                className="bg-white block mt-2 w-full p-4 text-black rounded-sm"
              />
            </label>
          </div>
          <div className="form-group md:col-span-2">
            <label htmlFor="Meddelande">
              Meddelande
              <textarea
                name="Meddelande"
                rows="4"
                className="block bg-white mt-2 w-full p-4 text-black rounded-sm"
              />
            </label>
          </div>
        </div>
        {/* <div className="form-group flex flex-row gap-4 items-center md:items-end ">
          <input type="checkbox" name="Samtycke" />
          <label htmlFor="Samtycke" className="leading-none ">
            Samtycker till att mina personuppgifter behandlas enligt
            integritetspolicyn.
          </label>
        </div> */}

        <div className="form-group">
          <button className="text-xl px-5    py-3 bg-primary text-white font-medium rounded-md">
            Skicka
          </button>
        </div>
      </form>
    </div>
  )
}
